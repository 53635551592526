<template>
  <div class="login-options">
    <ProfileOptions
      v-if="step === 0" title="Log In to Your Profile" heading="h1"
      @next-step="nextStep"
    >
      <div slot="footer" class="footer">
        Don’t have an account?
        <span v-if="resources" @click="$emit('signup')">
          Sign Up
        </span>
        <nuxt-link v-else :to="`/signup/${params}`">
          Sign Up
        </nuxt-link>
      </div>
    </ProfileOptions>
    <LoginForm
      v-if="step === 1"
      back-button
      hide-socials
      @prev-step="prevStep"
    />
  </div>
</template>

<script>
import ProfileOptions from '../ProfileOptions';
import LoginForm from '../../LoginForm';

export default {
  components: {
    ProfileOptions,
    LoginForm,
  },
  props: {
    resources: Boolean,
  },
  data() {
    return {
      step: 0,
    };
  },
  computed: {
    params() {
      return this.$route.fullPath.replace(this.$route.path, '') || '';
    },
  },
  methods: {
    nextStep() {
      this.step += 1;
    },
    prevStep() {
      this.step -= 1;
    },
  },
};
</script>

<style lang="scss">
  .login-options {
    min-height: 418px;
  }

  @include bp($bp-tablet) {
    .login-options {
      padding: var(--spacing-24);
    }
  }
</style>
