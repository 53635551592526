<template>
  <div>
    <LoginOptions :tracking="tracking" />
    <!-- <LoginForm v-else /> -->
  </div>
</template>

<script>
// import LoginForm from '../../LoginForm';
import LoginOptions from './LoginOptions';

export default {
  components: {
    // LoginForm,
    LoginOptions,
  },
  props: {
    tracking: { type: String, default: '' },
  },
};
</script>
